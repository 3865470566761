import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './werkwijze.style';

const Werkwijze = () => {
  return (
    <SectionWrapper id="aanpak">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading as="h5" content="Werkwijze/aanpak" />
            <Heading as="h3" content="Aanmelding en intake" />
            <Text content="Wanneer je contact met mij opneemt plannen we een belafspraak in. Dit gesprek is al een onderdeel van onze kennismaking. Na de belafspraak plannen we een intakegesprek in. Na het intakegesprek is er voor ons beide de mogelijkheid aan te geven of er wederzijds vertrouwen is als basis voor een traject. Wanneer dit het geval is stel ik een plan op wat richting geeft aan het traject en geef ik een inschatting van wat nodig is om de gewenste doelen te bereiken. Wanneer je je hierin kunt vinden dan starten we het traject. " />
			<Heading as="h3" content="Sessies" />
			<Text content="We bespreken de frequentie van de afspraken en het tijdstip zodat de afspraken tijdig in onze agenda’s passen. Tijdens de sessies zullen we niet alleen in gesprek zijn. Door mijn manier van begeleiden maken we de inzichten concreet en zichtbaar. We zijn bezig, schrijven, ervaren, reflecteren en maken gebruik van materialen. Ook reflecteren we tijdens elke sessie en evalueren we regelmatig.Indien gewenst betrekken we ook jouw systeem bij de begeleiding. Je ouders, verzorgers, partner, broers, zussen zijn welkom wanneer dit helpend is in het proces. Wanneer je onder de 18 jaar bent dan betrekken we ouders/verzorgers altijd bij het proces en de inhoudelijke begeleiding."/>
			<Heading as="h3" content="Locatie" />
			<Text content="De locatie stemmen we samen af. We kunnen terecht in één van de gespreksruimten die ik tot mijn beschikking heb in Hendrik-Ido-Ambacht en Dordrecht. De begeleiding kan ook bij jou thuis plaatsvinden. Wanneer het jou helpt om tijdens de afspraken buiten te wandelen dan is dat zeker mogelijk. Indien gewenst of nodig kunnen de afspraken ook via videogesprek/teams/zoom plaats vinden."/>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Werkwijze;
