import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppModern/Banner';
import DesignedAndBuilt from 'containers/AppModern/DesignedAndBuilt';
import Coaching from 'containers/AppModern/Coaching';
import School from 'containers/AppModern/School';
import Werkwijze from 'containers/AppModern/Werkwijze';
import Tarieven from 'containers/AppModern/Tarieven';
import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
import Testimonial from 'containers/AppModern/Testimonial';
import Doelgroep from 'containers/AppModern/Doelgroep';
import Footer from 'containers/AppModern/Footer';
import Service from 'containers/AppModern/Service';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';

const AppModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Praktijk Cipres" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
			<Doelgroep />
			<Testimonial />
			<Service />
            <DesignedAndBuilt />
			<Coaching/>
			<School/>
			<Werkwijze/>
			<Tarieven/>
            <TeamPortfolio />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default AppModern;
