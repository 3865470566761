import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './doelgroep.style';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import { Icon } from 'react-icons-kit';

const Doelgroep = () => {
  return (

    <SectionWrapper id="doelgroep">
	  <Container>
	  <ContentWrapper>
	  <div className="content">
	    <Heading as="h5" content="Doelgroep" />
		<Heading content="Vrouwen en meiden" />
		<Text content="Praktijk Cipres biedt coaching en begeleiding voor vrouwen en meiden vanaf ongeveer 12 jaar."/>
		<Text content="Ik sta met veel aandacht en plezier voor je klaar voor:"/>
		<div id="doellijst">
			<ul>
				<li><Icon icon={checkmarkCircled} />praktische ondersteuning bij plannen/organiseren </li>
				<li><Icon icon={checkmarkCircled} />coaching bij opvoeding en gezinsleven</li>
				<li><Icon icon={checkmarkCircled} />psychosociale begeleiding</li>
				<li><Icon icon={checkmarkCircled} />begeleiding na een diagnose en/of behandeltraject</li>
				<li><Icon icon={checkmarkCircled} />het invullen van de inhoudelijke zorg aan leerlingen</li>
			</ul>
		</div>
		<Text content="&nbsp;"/>
		<Text content="Hieronder vind je enkele voorbeelden van problematiek waarvoor ik begeleiding/coaching aanbied. Of jij je hier nu wel of niet in herkent, je kunt altijd contact opnemen voor een kennismaking."/>
	  </div>
	  </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Doelgroep;
