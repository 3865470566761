/* eslint-disable */
import React, {useState, useRef, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { playCircle } from 'react-icons-kit/fa/playCircle';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Rating from 'common/components/Rating';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Box from 'common/components/Box';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';

import microsoft from 'common/assets/image/appModern/envato-icon.png';
import videoBanner1 from 'common/assets/image/appModern/video-1.png';
import videoBanner2 from 'common/assets/image/appModern/video-2.png';
import circleBorder from 'common/assets/image/appModern/shape.svg';
import mockup from 'common/assets/image/appModern/banner2.png';
import mockup1 from 'common/assets/image/appModern/banner1.png';
import mockup2 from 'common/assets/image/appModern/banner3.png';
import mockup3 from 'common/assets/image/appModern/banner4.png';
import mockup4 from 'common/assets/image/appModern/banner5.png';
import mockup5 from 'common/assets/image/appModern/banner6.png';
import mockup6 from 'common/assets/image/appModern/banner7.png';
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

let currentLink="#begeleiding";

const images = [
  {
    original: `${mockup}`,
    originalAlt: 'Cipres intro',
	originalTitle: 'Cipressenlaan',
	heading: 'Wil je een stukje samen lopen?',
	caption: 'Als je het even nodig hebt lopen wij naast je',
	buttontext: 'Begeleiding',
	linkto: "#begeleiding",
  },
  {
    original: `${mockup1}`,
    originalAlt: 'Zeker naar school',
	originalTitle: 'test',
	heading: 'Met een zeker gevoel naar school?',
	caption: 'Samen zorgen we dat je schoolwerk op orde is en dat je je executieve functies traint',
	buttontext: 'Coaching',
	linkto: "#coaching"
  },
  {
    original: `${mockup3}`,
    originalAlt: 'Puzzel',
	originalTitle: 'test',
	heading: 'Kom je er zelf niet meer uit?',
	caption: 'Ik puzzel graag met je mee zodat je weer verder kunt',
	buttontext: 'Begeleiding',
	linkto: "#begeleiding"
  },
  {
    original: `${mockup6}`,
    originalAlt: 'Ouderschap',
	originalTitle: 'test',
	heading: 'Zie jij opvoeden als levenstaak?',
	caption: 'Groei als ouder',
	buttontext: 'Coaching',
	linkto: "#coaching"
  },
  {
    original: `${mockup2}`,
    originalAlt: 'slide two',
	originalTitle: 'test',
	heading: 'Is de rugzak van uw leerlingen te zwaar?',
	caption: 'Wanneer ondersteuning niet (voldoende) beschikbaar is binnen uw organisatie kunt u mij inhuren',
	buttontext: 'Lees meer',
	linkto: "#school2"
  },
  {
    original: `${mockup5}`,
    originalAlt: 'Chaos',
	originalTitle: 'test',
	heading: 'Is jouw dag chaotisch? ',
	caption: 'Samen creëren we rust en structuur',
	buttontext: 'Coaching',
	linkto: "#coaching"
  },
  {
    original: `${mockup4}`,
    originalAlt: 'slide four',
	originalTitle: 'test',
	heading: 'De draad kwijt? ',
	caption: 'We gaan samen zoeken',
	buttontext: 'Begeleiding',
	linkto: "#begeleiding"
  },
];

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  let item = images[0];
  const myText = useRef("My Original Text");
  
 
  const renderImage = (item) => {
	testFunct(item.caption);
	return (

			  <Image src={item.original} alt={item.originalAlt} />
	);
  };

  function linkFunction(){
	  window.location.href=images[galleryRef.current.getCurrentIndex()].linkto;
  }
  
  const elementRef = useRef();
  const headingRef = useRef();
  const galleryRef = useRef();
  const buttonRef = useRef();
  const linkRef= useRef();
  function testFunct(caption){
	
	var mydiv = elementRef.current;
	var mydiv2 = headingRef.current;
	if(mydiv!=null){
		if(galleryRef.current !=null){
			elementRef.current.firstChild.innerText = images[galleryRef.current.getCurrentIndex()].caption;
			headingRef.current.firstChild.innerText = images[galleryRef.current.getCurrentIndex()].heading;
			buttonRef.current.firstChild.innerText = images[galleryRef.current.getCurrentIndex()].buttontext;
			linkRef.current.firstChild.href = images[galleryRef.current.getCurrentIndex()].linkto;
			
		}
	}
  };

  function nextSlide(){
	  if(galleryRef.current !=null){
		var curslide = galleryRef.current.getCurrentIndex();
		curslide++;
		galleryRef.current.slideToIndex(curslide);		
	  }
  }
  function previousSlide(){
	  if(galleryRef.current !=null){
		var curslide = galleryRef.current.getCurrentIndex();
		curslide--;
		galleryRef.current.slideToIndex(curslide);		
	  }
  }
  return (
    <BannerWrapper id="home">
	<Container>
		<BannerContent>
			<div ref={headingRef}>
            <Heading
              as="h1"
              content={item.heading}
            />
			</div>
			<div ref={elementRef}>
            <Text
              content={item.caption}
            />
			</div>
            <ButtonGroup>
				<div ref={linkRef}>
				<AnchorLink href={currentLink} offset={90}>
				<div ref={buttonRef}>
					<Button className="primary" title="Begeleiding"/>
            	</div>
				</AnchorLink>
				</div>
			</ButtonGroup>
        </BannerContent>
		<BannerImage>
			<ImageGallery
			ref={galleryRef}
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            autoPlay={false}
			renderItem={renderImage}
          />
      </BannerImage>
	  </Container>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
	  <div className="leftNav">
	  <button type="button" className="image-gallery-icon image-gallery-left-nav" aria-label="Previous Slide" onClick={nextSlide}><svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg></button>
	  </div>
	  <button type="button" className="image-gallery-icon image-gallery-right-nav" aria-label="Next Slide" onClick={previousSlide}><svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg></button>
	
    </BannerWrapper>
  );
};

export default Banner;
		//<BannerImage><Image src={mockup} alt="Banner" /></BannerImage>
		//<ImageGallery
        //    items={images}
        //    className="Slider-img"
        //    showPlayButton={false}
        //    showFullscreenButton={false}
        //    showNav={true}
        //   autoPlay={false}
		//	renderItem={renderImage}
        //  /