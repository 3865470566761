import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './school.style';

const School = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        school {
		  title
		  title2
          slogan
		  slogan2
          description
		  description2
        }
      }
    }
  `);
  const { title, slogan, slogan2, description, title2, description2} =
    data.appModernJson.school;

  return (
    <SectionWrapper id="school">
      <Container>
        <ContentWrapper>
          <div className="content" id="school1">
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
            <Text content={description} />
          </div>
		  <div className="content" id="school2">
			<Heading as="h5" content={slogan2} />
            <Heading content={title2} />
            <Text content={description2} />
		  </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default School;
