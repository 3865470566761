import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './coaching.style';

const Coaching = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        coaching {
		  title
		  title2
          slogan
          description
		  description2
        }
      }
    }
  `);
  const { title, slogan, description, title2, description2} =
    data.appModernJson.coaching;

  return (
    <SectionWrapper id="coaching">
      <Container>
        <ContentWrapper>
          <div className="content" id="coaching1">
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
            <Text content={description} />
          </div>
		  <div className="content" id="coaching2">
            <Heading content={title2} />
            <Text content={description2} />
		  </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Coaching;
