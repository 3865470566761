import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BannerWrapper = styled.div`
  padding-top: 100px;
  //min-height: 802px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  
  background-image: -moz-linear-gradient(
    180deg,
    rgb(98, 119, 78) 0%,
    rgb(197, 217, 174) 100%
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(98, 119, 78) 0%,
    rgb(197, 217, 174) 100%
  );
  background-image: -ms-linear-gradient(
    180deg,
    rgb(98, 119, 78) 0%,
    rgb(197, 217, 174) 100%
  );
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    //min-height: 100vh;
  }
  @media only screen and (max-width: 846px) {
	min-height: 100vh;
	.leftNav{
		visibility: hidden;
	}
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
	.leftNav{
		visibility: hidden;
	}
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //min-height: calc(802px - 100px);
    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      //min-height: calc(100vh - 100px);
      margin-top: 35px;
      align-items: flex-start;
    }
    @media only screen and (max-width: 1023px) {
      //min-height: 100%;
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
  .bannerBottomShape {
    position: absolute;
    right: 0;
    bottom: -2px;
	height: 30%;
    @media only screen and (max-width: 999px) {
      display: none;
    }
  }
`;

export const BannerContent = styled.div`
  width: 55%;
  flex-shrink: 0;
  @media only screen and (min-width: 481px) and (max-width: 1023px) {
    flex-shrink: 0;
    width: 100%;
    margin-top: 25px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    flex-shrink: 0;
    width: 45%;
    margin-top: 25px;
  }
  @media only screen and (max-width: 480px) {
	flex-shrink: 0;
	width: 100%;
  }

  h1 {
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    color: ${themeGet('colors.headingColor', '#0F2137')};
    margin-bottom: 24px;
    @media only screen and (max-width: 1366px) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }

  p {
	color: ${themeGet('colors.primary', '#3d8f3d')};
    font-size: 24px;
    line-height: 30px;
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  color: ${themeGet('colors.menu', '#0D233E')};

  .rating {
    margin-right: 10px;
    .star {
      color: ${themeGet('colors.white', '#fff')};
    }
    .star-o {
      color: #e8e8e8;
    }
  }

  img {
    margin-left: 9px;
  }
`;

export const BannerImage = styled.div`
  flex-shrink: 0;
  img {
    margin-left: 100px;
    margin-top: 0px;
    @media only screen and (max-width: 1440px) {
      margin-top: 0px;
    }
  }
  @media only screen and (max-width: 1440px){
	  .image-gallery {
		  height: 70vh;
		  .image-gallery-slide img {
				height: 70vh;
		}
	  }
	  img {
		margin-left: 50px;
		margin-top: 0px;
		@media only screen and (max-width: 1440px) {
		  margin-top: 0px;
	  }
  }
  @media only screen and (max-width: 846px) {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 35px;

  .reusecore__button {
    text-transform: inherit;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;

    &.primary {
      background-color: ${themeGet('colors.white', '#fff')};
      color: rgb(15, 33, 55);
      font-size: 16px;
      letter-spacing: -0.1px;
      &:hover {
        box-shadow: #1e2a4a 0px 12px 24px -10px;
      }
    }

    &.text {
      font-size: 16px;
      color: ${themeGet('colors.white', '#000')};
      letter-spacing: -0.1px;
      font-weight: 500;
      .btn-icon {
        i {
          color: #000;
        }
        svg {
          width: auto;
          height: 25px;
        }
      }
    }
  }
`;
export const VideoGroup = styled.div`
  display: flex;
  margin-top: 60px;
  @media only screen and (max-width: 768px) {
    margin-top: 45px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  img {
    margin-right: 10px;
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
    //height: 100%;
    @media only screen and (max-width: 600px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`;
export const VideoWrapper = styled.div`
  max-width: 100%;
  width: 900px;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const CustomerWrapper = styled.div`
  max-width: 850px;
  padding: 0px 0 70px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding: 0px 0 55px;
  }
  @media only screen and (max-width: 1099px) {
    padding: 55px 0 55px;
    max-width: 920px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 25px 0 40px;
  }

  p {
    margin: 0 12px 0 0;
    color: ${themeGet('colors.menu', '#0D233E')};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (max-width: 1025px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
	width: 45%;
		img {
		margin: -100 12px;
		@media only screen and (max-width: 667px) {
		margin: 5px 10px;
    }
  }
  img {
    margin: -100 12px;
    @media only screen and (max-width: 667px) {
      margin: 5px 10px;
    }
  }
`;
export default BannerWrapper;
