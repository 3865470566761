import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './designedAndBuilt.style';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import { Icon } from 'react-icons-kit';

const DesignedAndBuilt = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        designAndBuilt {
		  title
		  title2
          slogan
          description
		  description2
		  description3
		  list2{
			  id
			  text
		  }
        }
      }
    }
  `);
  const { title, slogan, description, title2, description2, description3, list2 } =
    data.appModernJson.designAndBuilt;

  return (
    <SectionWrapper id="begeleiding">
      <Container>
        <ContentWrapper>
          <div className="content" id="begeleiding1">
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
            <Text content={description} />
          </div>
		  <div className="content" id="begeleiding2">
            <Heading content={title2} />
            <Text content={description2} />
			<ul>
                    {list2.map((item) => (
                      <li key={item.id}>
                        <Icon icon={checkmarkCircled} />{item.text}
                      </li>
                    ))}
            </ul>
			<Text content={description3} />
		  </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default DesignedAndBuilt;
