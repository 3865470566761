import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './tarieven.style';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import { Icon } from 'react-icons-kit';

const Tarieven = () => {
  return (
    <SectionWrapper id="tarieven">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading as="h5" content="Tarieven" />
            <Heading as="h4" content="Verwijzing is niet nodig" />
			<div id="tarieflijst">
				<ul>
					<li><Icon icon={checkmarkCircled} />Intakegesprek en opstellen van plan <b>€50,00</b></li>
					<li><Icon icon={checkmarkCircled} />Plannen en organiseren voor scholieren <b>€50,00 per uur</b></li>
					<li><Icon icon={checkmarkCircled} />Plannen en organiseren voor vrouwen <b>€50,00 per uur</b></li>
					<li><Icon icon={checkmarkCircled} />Coaching bij opvoeding en gezinsleven <b>€60,00 per uur</b></li>
					<li><Icon icon={checkmarkCircled} />Psychosociale begeleiding <b>€70,00 per uur</b></li>
					<li><Icon icon={checkmarkCircled} />Begeleiding na een diagnose en/of GGZ traject <b>€70,00 per uur</b></li>
				</ul>
			</div>
			
			<Text content="De diensten zijn vrijgesteld van BTW. Voor afspraken die niet 24 uur van tevoren afgezegd zijn wordt de helft van het uurtarief in rekening gebracht. "/>
			<Heading as="h3" content="Reiskosten"/>
			<Text content="Wanneer de coaching of begeleiding bij jou thuis plaatsvindt worden er pas bij meer dan 15 minuten reistijd vanaf Hendrik-Ido-Ambacht reiskosten berekend. Wanneer de reistijd langer is dan 15 minuten wordt er 25 cent per kilometer gerekend en ook de reistijd wordt doorgerekend."/>
			<Heading as="h3" content="Toegankelijkheid"/>
			<Text content="Ik vind het belangrijk dat iedereen, ook diegenen die minder te besteden hebben, van onze begeleiding en coaching gebruik kunnen maken. In beperkte mate bied ik jongeren, studenten en minima de mogelijkheid om in aanmerking te komen voor een kortingstarief. Daarover kan overleg plaatsvinden voorafgaand aan een eventuele intake."/>




          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Tarieven;
