import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';

import Container from 'common/components/UI/ContainerTwo';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BlogPost from 'common/components/BlogPost';
import Heading from 'common/components/Heading';
import { SectionHeader } from '../appModern.style';
import Section, { ServiceWrapper } from './service.style';

const Service = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        services {
          id
          title
          desc
          link
          icon {
            publicURL
          }
        }
      }
    }
  `);
  const services = data.appModernJson.services;
  return (
    <Section id="service">
      <Container>
        <SectionHeader>
          <Fade up>
          <Heading
		  as="h5" content="producten en diensten" />
		  <Heading content="Begeleiding, Coaching en dienstverlening" />
        </Fade>
		</SectionHeader>
        <ServiceWrapper>
          {services.map((service) => (
            <Fade key={service.id} up delay={100 * service.id}>
              <BlogPost
                className="serviceItem"
                thumbUrl={service.icon.publicURL}
                title={service.title}
                excerpt={service.desc}
                link={
                  <AnchorLink href={service.link} offset={90}>
                    Lees verder <Icon icon={chevronRight} />
                  </AnchorLink>
                }
              />
            </Fade>
          ))}
        </ServiceWrapper>
      </Container>
    </Section>
  );
};

export default Service;
